import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Loading from "elements/Loading"

const FlexmedCashless = ({ pageContext }) => {
  const { nextPath } = pageContext
  useEffect(() => {
    navigate(nextPath)
  }, [nextPath])

  return <Loading />
}

export default FlexmedCashless